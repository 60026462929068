import React, { useState } from "react";
import InitiativeCharacter from "../components/initiative/InitiativeCharacter";
import { StoreContainer } from "../store";
import CustomMonsterList from "../components/initiative/CustomMonsterList";
import HomeLink from "../components/HomeLink";
import Layout from "../components/Layout";

function InitiativeList() {
  const [charName, setCharName] = useState("");
  const [initiative, setInitiative] = useState("");
  const store = StoreContainer.useContainer();

  return (
    <Layout>
      <HomeLink />
      <h1>Initiative Tracker</h1>
      <form
        className="flex flex-row justify-center items-center flex-wrap my-4"
        onSubmit={(e) => {
          e.preventDefault();
          store.handleSubmit(charName, parseInt(initiative));
        }}
      >
        <label>
          Character name:
          <input
            className="border-b-2 border-black w-80 mx-3 dark:border-white dark:bg-black"
            maxLength={40}
            type="text"
            name="CharName"
            onChange={(e) => {
              const newName = e.target.value;
              setCharName(newName);
            }}
            value={charName}
          />
        </label>
        <label>
          Initiative:
          <input
            className="border-b-2 border-black w-14 mx-3 dark:border-white dark:bg-black"
            maxLength={2}
            inputMode="numeric"
            name="Initiative"
            onChange={(e) => {
              const newInitiative = e.target.value;

              if (newInitiative.match("^[0-9]+$") || newInitiative === "") {
                setInitiative(newInitiative);
              }
            }}
            value={initiative}
          />
        </label>
        <button
          className="bg-[#0b70fe] text-white m-2 px-8 py-4 rounded-lg border-2 border-black dark:border-white dark:bg-black"
          type="submit"
          value="Submit"
        >
          Submit
        </button>
      </form>
      <CustomMonsterList />
      <div className="flex justify-between">
        <button
          className="h-44 w-96 my-10 rounded-xl text-3xl text-white border-2 border-black bg-[#ff451b] dark:border-white"
          onClick={() => {
            store.prevInitPos();
          }}
        >
          Previous
        </button>
        <button
          className="h-44 w-96 my-10 rounded-xl text-3xl text-white border-2 border-black bg-[#ff451b] dark:border-white"
          onClick={() => {
            store.nextInitPos();
          }}
        >
          Next
        </button>
      </div>
      <div className="flex flex-row justify-center items-center">
        <h2>Round {store.initiativeRound}</h2>
        <button
          className="h-20 w-32 my-8 mx-6 rounded-lg border-2 border-black dark:border-white"
          onClick={() => {
            store.resetInitRound();
          }}
        >
          Reset
        </button>
      </div>
      {store.initiativeOrder.length > 0 && (
        <div className="border-2 border-black rounded-lg mb-6 dark:border-white">
          <div className="md:flex md:flex-row p-10 border-b-2 border-black hidden align-center dark:border-white">
            {[
              "Adjust position",
              "Initiative position",
              "Character name",
              "Initiative score",
              "Hitpoints",
              "Delete",
            ].map((title) => (
              <p
                key={`header-${title}`}
                className="flex-1 flex break-words justify-center text-center font-bold"
              >
                {title}
              </p>
            ))}
          </div>
          {store.initiativeOrder.map((character: any, index: number) => {
            return (
              <InitiativeCharacter
                key={`character${index}`}
                character={character}
                index={index}
              />
            );
          })}
        </div>
      )}
    </Layout>
  );
}

export default InitiativeList;
