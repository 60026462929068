import React from "react";
import Routes from "./routes/root";
import { StoreContainer } from "./store";

function App() {
  return (
    <>
      <StoreContainer.Provider>
        <Routes />
      </StoreContainer.Provider>
    </>
  );
}

export default App;
