import Client from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

export const projectId = "gqndjfry";
export const dataset = "production";

const sanity = Client({
  projectId,
  dataset,
  useCdn: false,
});

const urlBuilderFactory = imageUrlBuilder(sanity);

export function urlFor(image: SanityImageSource) {
  return urlBuilderFactory.image(image);
}

export async function getMonsters(monsterSearchFilter?: string) {
  const monsterAttributes = `{
    ...,
    "languages": languages[].language,
    "abilityScores": monsterAbilityScores{"strength": strScore, "dexterity": dexScore, "constitution": constScore, "intelligence": intScore, "wisdom": wisScore, "charisma": chaScore},
    "saveProficiencies": monsterSaveProficiencies,
    legendaryActions{legendaryActionPoints, "actions": legendaryAbilities}
  }`;

  if (monsterSearchFilter) {
    return sanity.fetch(
      `*[_type=="monster" && name match "*${monsterSearchFilter}*"]${monsterAttributes}`
    );
  } else {
    return sanity.fetch(`*[_type=="monster"]${monsterAttributes}`);
  }
}

export async function getCampaigns() {
  return sanity.fetch(
    `*[_type=="campaign"]{campaignTitle, "campaignSlug": campaignSlug.current}`
  );
}

export async function getCampaign(campaignSlug: string) {
  return sanity.fetch(
    `*[_type=="campaign" && campaignSlug.current=="${campaignSlug}"][0]{
          campaignTitle, "campaignSlug": campaignSlug.current, 
          folders[]->{folderTitle, 
                articles[]->{articleTitle, "articleSlug": articleSlug.current}
          }
      }`
  );
}

export async function getArticle(articleSlug: string) {
  return sanity.fetch(
    `*[_type=="article" && articleSlug.current=="${articleSlug}"][0]{articleTitle, articleSlug, content}`
  );
}
