import React, { useState, useEffect } from "react";
import MonsterCard from "./MonsterCard";
import { getMonsters } from "../../lib/sanity";

const CustomMonsterList = () => {
  const [fullMonsterList, setFullMonsterList] = useState([]);
  const [monsterList, setMonsterList] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [monsterListOpen, setMonsterListOpen] = useState(false);

  const monstersPerPage = 10;

  useEffect(() => {
    getMonsters()
      .then((response) => {
        setFullMonsterList(response);
        setMonsterList(response.slice(0, monstersPerPage));
        setPageCount(Math.ceil(Object.keys(response).length / monstersPerPage));
      })
      .catch((error) => console.log("Server error: ", error));
  }, []);

  useEffect(() => {
    setMonsterList(
      fullMonsterList.slice(
        page * monstersPerPage,
        (page + 1) * monstersPerPage
      )
    );
  }, [page, fullMonsterList]);

  const handleSearch = (searchWord: string) => {
    getMonsters(searchWord)
      .then((response) => {
        setFullMonsterList(response);
        setMonsterList(response.slice(0, monstersPerPage));
        setPageCount(Math.ceil(Object.keys(response).length / monstersPerPage));
        setPage(0);
      })
      .catch((error) => console.log("Server error: ", error));
  };

  return (
    <>
      <button
        className="border-[1px] border-black w-fit px-8 py-4 mb-4 dark:border-white"
        onClick={() => {
          setMonsterListOpen(!monsterListOpen);
        }}
      >
        {monsterListOpen ? "Close " : "Open "} custom monster list
      </button>
      {monsterListOpen && (
        <div className="w-fit">
          <div>
            <b>Search:</b>{" "}
            <input
              className="mb-6 border-b-2 border-black dark:border-white dark:bg-black"
              type="text"
              name="searchBar"
              onChange={(e) => {
                const newSearchFilter = e.target.value;
                setSearchFilter(newSearchFilter);
                handleSearch(newSearchFilter);
              }}
              value={searchFilter}
            />
          </div>
          <div className="border-[1px] border-black rounded-md dark:border-white">
            {monsterList && monsterList.length > 0
              ? monsterList.map((monster, index) => {
                  return (
                    <MonsterCard
                      key={`custom-monster${index}`}
                      monsterData={monster}
                      showBorderBottom={index !== monsterList.length - 1}
                    />
                  );
                })
              : "No monsters here"}
          </div>
          <div className="flex flex-row mt-4">
            <button
              className="border-[1px] py-2 px-4 border-black dark:border-white"
              onClick={() => {
                if (page > 0) {
                  setPage(page - 1);
                }
              }}
            >
              previous
            </button>
            <p className="mx-2">
              {page + 1} of {pageCount}
            </p>
            <button
              className="border-[1px] py-2 px-4 border-black dark:border-white"
              onClick={() => {
                if (page + 1 < pageCount) {
                  setPage(page + 1);
                }
              }}
            >
              next
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomMonsterList;
