import React, { useState } from "react";
import MonsterModal from "./MonsterModal";
import { FiPlus } from "react-icons/fi";
import { StoreContainer } from "../../store";
import { CustomMonster } from "../../types";

type MonsterCardProps = {
  showBorderBottom: boolean;
  monsterData: CustomMonster;
};

const MonsterCard = ({ showBorderBottom, monsterData }: MonsterCardProps) => {
  const store = StoreContainer.useContainer();

  const [monsterModalIsOpen, setMonsterModalOpen] = useState(false);

  return (
    <div
      className={`${
        showBorderBottom ?? "border-b-[1px] border-black dark:border-white"
      } p-4 flex flex-row`}
    >
      <button className="self-center mr-8">
        <FiPlus
          size={25}
          onClick={() => {
            const initiativeDiceRoll = Math.floor(Math.random() * 20) + 1;
            const dexModifier =
              Math.floor(monsterData.abilityScores.dexterity / 2) - 5;
            const totalInitiative = initiativeDiceRoll + dexModifier;
            store.handleSubmit(monsterData.name, totalInitiative, monsterData);
          }}
        />
      </button>
      <h3
        onClick={() => {
          setMonsterModalOpen(true);
        }}
      >
        {monsterData.name}
      </h3>
      <MonsterModal
        monsterModalIsOpen={monsterModalIsOpen}
        setMonsterModalOpen={setMonsterModalOpen}
        monsterDetails={monsterData}
      />
    </div>
  );
};

export default MonsterCard;
