import { useEffect, useState } from "react";
import HomeLink from "../components/HomeLink";
import Layout from "../components/Layout";
import LinkRoute from "../components/LinkRoute";
import { getCampaigns } from "../lib/sanity";
import { Campaign } from "../types";

const Wiki = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  useEffect(() => {
    getCampaigns()
      .then((response) => {
        setCampaigns(response);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }, []);

  return (
    <Layout>
      <HomeLink />
      <h1>Campaigns</h1>
      {campaigns.map((campaign, index) => {
        return (
          <LinkRoute key={`campaign-${index}`} to={campaign.campaignSlug}>
            {`${campaign.campaignTitle} >>`}
          </LinkRoute>
        );
      })}
    </Layout>
  );
};

export default Wiki;
