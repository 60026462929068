import React from "react";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { PortableText } from "@portabletext/react";
import { CustomMonster } from "../../types";
import { scoreToModifier } from "../../utils/abilityScore";

type ModalProps = {
  monsterDetails: CustomMonster;
  monsterModalIsOpen: boolean;
  setMonsterModalOpen: (modalOpen: boolean) => void;
};

const MonsterModal = (props: ModalProps) => {
  const { monsterDetails, monsterModalIsOpen, setMonsterModalOpen } = props;

  function closeModal() {
    setMonsterModalOpen(false);
  }

  const Abilities = () => {
    return (
      <div className="whitespace-pre-wrap pt-2 pb-16">
        <h2>Abilities</h2>
        <hr />
        {monsterDetails.specialAbilities.map((ability, index: number) => {
          return (
            <div key={`ability${index}`}>
              <p>
                <b>{ability.name}.</b>
              </p>
              <PortableText value={ability.desc} />
            </div>
          );
        })}
      </div>
    );
  };

  const SavingThrowsText = () => {
    if (!monsterDetails.saveProficiencies) {
      return <p></p>;
    }

    const saves: string[] = [];
    const profBonus = monsterDetails.profBonus;
    if (monsterDetails.saveProficiencies.strSave) {
      saves.push(
        `STR +${
          scoreToModifier(monsterDetails.abilityScores.strength) + profBonus
        }`
      );
    }

    if (monsterDetails.saveProficiencies.dexSave) {
      saves.push(
        `DEX +${
          scoreToModifier(monsterDetails.abilityScores.dexterity) + profBonus
        }`
      );
    }
    if (monsterDetails.saveProficiencies.conSave) {
      saves.push(
        `CON +${
          scoreToModifier(monsterDetails.abilityScores.constitution) + profBonus
        }`
      );
    }
    if (monsterDetails.saveProficiencies.intSave) {
      saves.push(
        `INT +${
          scoreToModifier(monsterDetails.abilityScores.intelligence) + profBonus
        }`
      );
    }
    if (monsterDetails.saveProficiencies.wisSave) {
      saves.push(
        `WIS +${
          scoreToModifier(monsterDetails.abilityScores.wisdom) + profBonus
        }`
      );
    }

    if (monsterDetails.saveProficiencies.chaSave) {
      saves.push(
        `CHA +${
          scoreToModifier(monsterDetails.abilityScores.charisma) + profBonus
        }`
      );
    }

    return <p>{saves.length > 0 && `Saving throws: ${saves.join(",")}`}</p>;
  };

  // const skillsText = () => {
  //   let skillsStatsText = "";
  //   Object.keys(monsterDetails.skills).forEach((skill) => {
  //     skillsStatsText =
  //       skillsStatsText +
  //       skill.substring(0, 1).toUpperCase() +
  //       skill.substring(1) +
  //       " +" +
  //       monsterDetails.skills[skill] +
  //       ", ";
  //   });

  //   if (skillsStatsText.length > 0) {
  //     const removeLastComma = skillsStatsText.trim().slice(0, -1);
  //     return "Skills: " + removeLastComma;
  //   } else {
  //     return "";
  //   }
  // };

  type MonsterAbilityProps = {
    abilityName: string;
    abilityNr: number;
  };

  const MonsterAbility = ({ abilityName, abilityNr }: MonsterAbilityProps) => {
    return (
      <div className="px-2 pb-2">
        <h3>
          {abilityName}: ({abilityNr >= 10 ? "+" : null}
          {Math.floor(abilityNr / 2 - 5)})
        </h3>
        <p>{abilityNr}</p>
      </div>
    );
  };

  const MonsterSpeed = () => {
    const SpeedText =
      `Speed: ` +
      monsterDetails.movementSpeed.reduce((element, currValue, index) => {
        let text = "";
        if (currValue.type === "hover") {
          return element + "";
        } else if (currValue.type === "fly" && currValue.length) {
          text = element + ` fly ${currValue.length}ft. (hover)`;
        } else {
          text =
            element +
            ` ${currValue.type !== "walk" ? currValue.type : ""} ${
              currValue.length
            }ft.`;
        }

        if (index !== monsterDetails.movementSpeed.length - 1) {
          text += ",";
        }

        return text;
      }, "");

    return <p>{SpeedText}</p>;
  };

  return (
    <Modal
      className="scrollbar bg-white h-[75vh] overflow-y-auto overflow-x-hidden border-[0.2rem] border-black top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 absolute w-[80vw] px-8 pb-8 dark:border-white"
      isOpen={monsterModalIsOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      contentLabel="Example Modal"
    >
      <div className="sticky top-0 bg-white px-4 pt-4 pb-2">
        <AiOutlineClose
          className="float-right cursor-pointer"
          size={25}
          onClick={closeModal}
        />
        <h1>{`${monsterDetails.name} (${
          monsterDetails.size ? monsterDetails.size : ""
        } ${monsterDetails.type ? monsterDetails.type : ""}, ${
          monsterDetails.alignment ? monsterDetails.alignment : ""
        })`}</h1>
        <hr />
      </div>
      <div className="m-2">
        <p>
          Armor class: {monsterDetails.armorClass}{" "}
          {monsterDetails.armorDescription
            ? `(${monsterDetails.armorDescription})`
            : null}{" "}
        </p>
        {monsterDetails.monsterHp && monsterDetails.abilityScores && (
          <p>
            Hit points:{" "}
            {Math.floor(
              (monsterDetails.monsterHp.hitDice / 2 + 0.5) *
                monsterDetails.monsterHp.hitDiceAmount
            ) + scoreToModifier(monsterDetails.abilityScores.constitution)}{" "}
            ({monsterDetails.monsterHp.hitDiceAmount}d
            {monsterDetails.monsterHp.hitDice} +{" "}
            {scoreToModifier(monsterDetails.abilityScores.constitution)})
          </p>
        )}
        {monsterDetails.movementSpeed && <MonsterSpeed />}
        <hr />
        {monsterDetails.abilityScores && (
          <div className="flex flex-row items-center justify-evenly text-center flex-wrap">
            <MonsterAbility
              abilityName={"Str"}
              abilityNr={monsterDetails.abilityScores.strength}
            />
            <MonsterAbility
              abilityName={"Dex"}
              abilityNr={monsterDetails.abilityScores.dexterity}
            />
            <MonsterAbility
              abilityName={"Con"}
              abilityNr={monsterDetails.abilityScores.constitution}
            />
            <MonsterAbility
              abilityName={"Int"}
              abilityNr={monsterDetails.abilityScores.intelligence}
            />
            <MonsterAbility
              abilityName={"Wis"}
              abilityNr={monsterDetails.abilityScores.wisdom}
            />
            <MonsterAbility
              abilityName={"Cha"}
              abilityNr={monsterDetails.abilityScores.charisma}
            />
          </div>
        )}
        <hr />

        <div className="whitespace-pre-wrap pt-2 pb-16">
          {monsterDetails.abilityScores && <SavingThrowsText />}
          <p>
            {/* {monsterDetails.skills &&
              Object.keys(monsterDetails.skills).length !== 0 &&
              skillsText()} */}
          </p>
          <p>{`Damage resistances: ${monsterDetails.resistances ?? ""}`}</p>
          <p>{`Damage immunities: ${monsterDetails.damageImmunities ?? ""}`}</p>
          <p>{`Condition immunities: ${
            monsterDetails.conditionImmunities ?? ""
          }`}</p>
          {monsterDetails.senses && (
            <p>
              Senses:{" "}
              {monsterDetails.senses.reduce((prev, curr, index) => {
                let senseText = prev + `${curr.sense} ${curr.senseDistance}ft.`;
                if (monsterDetails.senses.length - 1 !== index) {
                  senseText += ", ";
                }
                return senseText;
              }, "")}
            </p>
          )}
          {monsterDetails.languages && (
            <p>
              Languages:{" "}
              {monsterDetails.languages
                .map(
                  (language) =>
                    language.charAt(0).toUpperCase() + language.substring(1)
                )
                .join(", ")}
            </p>
          )}
          <p>Challenge rating: {monsterDetails.challengeRating}</p>
        </div>
        {monsterDetails.specialAbilities &&
          monsterDetails.specialAbilities.length > 0 && <Abilities />}
        <h2>Actions</h2>
        <hr />
        <div className="whitespace-pre-wrap pt-2 pb-16">
          {monsterDetails.actions
            ? monsterDetails.actions.map((action, index: number) => {
                return (
                  <div key={`actions${index}`}>
                    <p>
                      <b>{action.name}.</b> {action.desc}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
        {monsterDetails.legendaryActions && (
          <>
            <h1>Legendary actions</h1>
            <hr />
            <div className="whitespace-pre-wrap pt-2 pb-16">
              <p>{/*FILL IN HERE*/}</p>
            </div>
            {monsterDetails.legendaryActions.actions.map(
              (action, index: number) => {
                return (
                  <div key={`actions${index}`}>
                    <p>
                      <b>{action.name}.</b> {action.desc}
                    </p>
                  </div>
                );
              }
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default MonsterModal;
