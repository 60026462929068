import React from "react";
import { Routes, Route } from "react-router-dom";
import ArticlePage from "../pages/ArticlePage";

import ArticleOverview from "../pages/ArticleOverview";
import Home from "../pages/Home";
import Initiative from "../pages/InitiativeList";
import Wiki from "../pages/Wiki";

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/initiative" element={<Initiative />} />
      <Route path="/wiki" element={<Wiki />} />
      <Route path="/wiki/:campaign" element={<ArticleOverview />} />
      <Route path="/wiki/:campaign/:article" element={<ArticlePage />} />
    </Routes>
  );
};

export default Main;
