import { ReactNode } from "react";
import ColorSchemeButton from "./ColorSchemeButton";

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="container">
      <ColorSchemeButton />
      {children}
    </div>
  );
};

export default Layout;
