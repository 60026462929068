import { PortableText } from "@portabletext/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import LinkRoute from "../components/LinkRoute";
import PortableTextComponents from "../components/PortableComponents";
import { getArticle } from "../lib/sanity";
import { Article } from "../types";
import ErrorPage from "./Error404Page";

const ArticlePage = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [article, setArticle] = useState<Article>();

  useEffect(() => {
    if (params.article) {
      getArticle(params.article).then((response) => {
        setIsLoading(false);
        setArticle(response);
      });
    }
  }, [params]);

  if (!isLoading && article) {
    return (
      <Layout>
        {Object.keys(article).length === 0 ? (
          <ErrorPage />
        ) : (
          <div className="mx-[8vw] md:mx-[12vw] xl:mx-[20vw]">
            {params.campaign && (
              <LinkRoute to={`/wiki/${params.campaign}`}>
                {`<< Back to Overview`}
              </LinkRoute>
            )}
            <h1>{article.articleTitle}</h1>
            <div className="my-10">
              <PortableText
                value={article.content}
                components={PortableTextComponents}
              />
            </div>
          </div>
        )}
      </Layout>
    );
  }
  return <Layout>{isLoading && <p>Loading...</p>}</Layout>;
};

export default ArticlePage;
