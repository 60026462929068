import React from "react";
import Layout from "../components/Layout";
import LinkRoute from "../components/LinkRoute";

const Home = () => {
  return (
    <Layout>
      <h1>This is my website</h1>
      <div className="mt-4 flex flex-col">
        <LinkRoute
          size="lg"
          to="/initiative"
        >{`Initative Tracker >>`}</LinkRoute>
        <LinkRoute size="lg" to="/wiki">{`Campaign wiki >>`}</LinkRoute>
      </div>
    </Layout>
  );
};

export default Home;
