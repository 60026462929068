import { useState } from "react";
import { createContainer } from "unstated-next";
import { CustomMonster } from "./types";
import { scoreToModifier } from "./utils/abilityScore";

type InitiativeList = {
  initiative: number;
  name: string;
  monsterData: CustomMonster;
  hp: number;
  activeInput: boolean;
}[];

export const useStore = () => {
  const localInitList = localStorage.getItem("initList");
  const initPos = localStorage.getItem("initPos");
  const initRound = localStorage.getItem("initRound");

  const list = localInitList ? JSON.parse(localInitList) : [];
  const pos = initPos ? parseInt(initPos) : 0;

  const round = initRound ? parseInt(initRound) : 1;

  const [initiativeOrder, setInitiativeOrder] = useState(list);
  const [initiativePos, setInitiativePos] = useState(pos);
  const [initiativeRound, setInitiativeRound] = useState(round);

  const handleSubmit = (
    charName: string,
    initiative: number,
    monsterData?: CustomMonster
  ) => {
    if (+initiative === initiative) {
      const newInitiativeOrder = [...initiativeOrder];
      const charHp = monsterData
        ? Math.floor(
            (monsterData.monsterHp.hitDice / 2 + 0.5) *
              monsterData.monsterHp.hitDiceAmount
          ) + scoreToModifier(monsterData.abilityScores.constitution)
        : "0";

      newInitiativeOrder.push({
        name: charName,
        initiative: initiative,
        monsterData: monsterData,
        hp: charHp,
        activeInput: false,
      });

      sortInitiativeList(newInitiativeOrder);
    }
  };

  const prevInitPos = () => {
    var newPos = initiativePos - 1;
    if (newPos < 0) {
      newPos = initiativeOrder.length - 1;
      if (initiativeRound > 1) {
        const newInitRound = initiativeRound - 1;
        setInitiativeRound(newInitRound);
        localStorage.setItem("initRound", JSON.stringify(newInitRound));
      }
    }
    setInitiativePos(newPos);
    const stringPos = newPos.toString();
    localStorage.setItem("initPos", stringPos);
  };

  const nextInitPos = () => {
    var newPos = initiativePos + 1;
    if (newPos >= initiativeOrder.length) {
      newPos = 0;
      const newInitRound = initiativeRound + 1;
      setInitiativeRound(newInitRound);
      localStorage.setItem("initRound", JSON.stringify(newInitRound));
    }
    const stringPos = newPos.toString();
    setInitiativePos(newPos);
    localStorage.setItem("initPos", stringPos);
  };

  const resetInitRound = () => {
    setInitiativeRound(1);
    localStorage.setItem("initRound", JSON.stringify(1));
  };

  const changeArrayPosition = (from: number, to: number) => {
    const newInitiativeOrder = [...initiativeOrder];
    const elementToMove = newInitiativeOrder.splice(from, 1)[0];
    newInitiativeOrder.splice(to, 0, elementToMove);
    setInitiativeOrder(newInitiativeOrder);
    localStorage.setItem("initList", JSON.stringify(newInitiativeOrder));
  };

  const getCharInitiative = (index: number) => {
    return initiativeOrder[index].initiative;
  };

  const changeCharInitiative = (index: number, newInitiative: number) => {
    const updatedInitiativeOrder = [...initiativeOrder];
    const updatedChar = {
      ...updatedInitiativeOrder[index],
      initiative: newInitiative,
    };

    updatedInitiativeOrder[index] = updatedChar;

    setInitiativeOrder(updatedInitiativeOrder);
    localStorage.setItem("initList", JSON.stringify(updatedInitiativeOrder));
    sortInitiativeList(updatedInitiativeOrder);
  };

  const getCharName = (index: number) => {
    return initiativeOrder[index].name;
  };

  const changeCharName = (index: number, newName: string) => {
    const updatedInitiativeOrder = [...initiativeOrder];
    const updatedChar = {
      ...updatedInitiativeOrder[index],
      name: newName,
    };

    updatedInitiativeOrder[index] = updatedChar;

    setInitiativeOrder(updatedInitiativeOrder);
    localStorage.setItem("initList", JSON.stringify(updatedInitiativeOrder));
    sortInitiativeList(updatedInitiativeOrder);
  };

  const sortInitiativeList = (initiativeList: InitiativeList) => {
    const sortedInitiativeList = [...initiativeList];

    sortedInitiativeList.sort((characterA, characterB) => {
      if (characterA.initiative < characterB.initiative) {
        return 1;
      } else if (characterA.initiative > characterB.initiative) {
        return -1;
      }
      return 0;
    });
    setInitiativeOrder(sortedInitiativeList);

    localStorage.setItem("initList", JSON.stringify(sortedInitiativeList));
  };

  const getCharHp = (index: number) => {
    return initiativeOrder[index].hp;
  };

  const changeCharHp = (index: number, newHp: string) => {
    const updatedInitiativeOrder = [...initiativeOrder];
    const updatedChar = { ...updatedInitiativeOrder[index], hp: newHp };
    updatedInitiativeOrder[index] = updatedChar;
    setInitiativeOrder(updatedInitiativeOrder);
    localStorage.setItem("initList", JSON.stringify(updatedInitiativeOrder));
  };

  const removeChar = (index: number) => {
    const newInitiativeOrder = [...initiativeOrder];
    newInitiativeOrder.splice(index, 1);

    setInitiativeOrder(newInitiativeOrder);
    localStorage.setItem("initList", JSON.stringify(newInitiativeOrder));

    if (initiativePos === initiativeOrder.length - 1) {
      var newPos = initiativePos - 1;
      if (newPos < 0 && initiativeOrder.length > 0) {
        newPos = initiativeOrder.length - 1;
      } else if (initiativeOrder.length === 0) {
        newPos = 0;
      }

      setInitiativePos(newPos);
      const stringPos = newPos.toString();
      localStorage.setItem("initPos", stringPos);
    }
  };

  let initialColorScheme = localStorage.getItem("colorScheme");
  const [colorScheme, setColorScheme] = useState(initialColorScheme);

  const getColorScheme = () => {
    return colorScheme;
  };

  const changeColorScheme = () => {
    if (colorScheme === "dark") {
      localStorage.setItem("colorScheme", "light");
      setColorScheme("light");
    } else {
      localStorage.setItem("colorScheme", "dark");
      setColorScheme("dark");
    }
  };

  return {
    initiativeOrder,
    initiativePos,
    initiativeRound,
    resetInitRound,
    changeCharHp,
    getCharHp,
    getCharInitiative,
    changeArrayPosition,
    changeCharInitiative,
    getCharName,
    changeCharName,
    handleSubmit,
    nextInitPos,
    prevInitPos,
    removeChar,
    getColorScheme,
    changeColorScheme,
  };
};
export const StoreContainer = createContainer(useStore);
