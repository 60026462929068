import { ReactNode } from "react";
import { Link } from "react-router-dom";

type LinkRouteProps = {
  to: string;
  children: ReactNode;
  size?: "sm" | "lg";
};

const LinkRoute = ({ children, to, size }: LinkRouteProps) => {
  const textVariant = {
    sm: "text-2xl",
    lg: "text-4xl",
  };

  return (
    <Link
      className={`w-fit inline-flex my-6 mr-1 underline ${
        size ? textVariant[size] : "text-3xl"
      }`}
      to={to}
    >
      {children}
    </Link>
  );
};

export default LinkRoute;
