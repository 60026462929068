import { useEffect } from "react";
import { StoreContainer } from "../store";

const ColorSchemeButton = () => {
  const store = StoreContainer.useContainer();

  const colorScheme = store.getColorScheme();

  useEffect(() => {
    if (colorScheme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [colorScheme]);

  return (
    <button
      className="border-2 border-black dark:border-white self-end py-2 px-4"
      onClick={() => store.changeColorScheme()}
    >
      {"Lights "}
      {!colorScheme || colorScheme === "light" ? "on" : "off"}
    </button>
  );
};

export default ColorSchemeButton;
