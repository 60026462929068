import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import LinkRoute from "../components/LinkRoute";
import { getCampaign } from "../lib/sanity";
import { Campaign, Folder } from "../types";
import ErrorPage from "./Error404Page";

const ArticleOverview = () => {
  const params = useParams();
  const [campaign, setCampaign] = useState<Campaign>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (params.campaign) {
      getCampaign(params.campaign).then((response) => {
        setIsLoading(false);
        setCampaign(response);
      });
    }
  }, [params]);

  useEffect(() => {
    console.log(campaign);
  }, [campaign]);

  if (!isLoading && campaign) {
    return (
      <Layout>
        {Object.keys(campaign).length === 0 ? (
          <ErrorPage />
        ) : (
          <>
            <LinkRoute to="/wiki">{`<< Back to wiki`}</LinkRoute>
            <h1>{campaign.campaignTitle}</h1>
            {campaign.folders.map((folder: Folder, index) => (
              <FolderButton
                key={`folder-${index}`}
                folder={folder}
                campaignSlug={campaign.campaignSlug}
              />
            ))}
          </>
        )}
      </Layout>
    );
  }

  return <Layout>{isLoading && <p>Loading...</p>}</Layout>;
};

const FolderButton = ({
  folder,
  campaignSlug,
}: {
  folder: Folder;
  campaignSlug: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        className="my-8 w-fit underline text-[2rem] font-[400]"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {isOpen ? "-" : "+"} {folder.folderTitle}
      </button>

      <div>
        {isOpen && folder.articles && folder.articles.length > 0 && (
          <div className="flex flex-col ml-12">
            {folder.articles.map((article, index) => {
              return (
                <LinkRoute
                  size="sm"
                  key={`link-route-${index}`}
                  to={`/wiki/${campaignSlug}/${article.articleSlug}`}
                >
                  {`${article.articleTitle} >>`}
                </LinkRoute>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default ArticleOverview;
