import React, { useEffect, useState } from "react";
import { StoreContainer } from "../../store";
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import MonsterModal from "./MonsterModal";
import { CustomMonster } from "../../types";

type InitiativeCharacterProps = {
  index: number;
  character: { monsterData?: CustomMonster; name: string };
};

const InitiativeCharacter = ({
  character,
  index,
}: InitiativeCharacterProps) => {
  const store = StoreContainer.useContainer();

  const [monsterModalIsOpen, setMonsterModalOpen] = useState(false);
  const [charInit, setCharInit] = useState(store.getCharInitiative(index));
  const [activeInitiativeInput, setActiveInitiativeInput] = useState(false);
  const [charName, setCharName] = useState(store.getCharName(index));
  const [activeNameInput, setActiveNameInput] = useState(false);

  const handleInitiativeKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const newInitiative = parseInt(event.currentTarget.value);
    if (event.key === "Enter" && +event.currentTarget.value === newInitiative) {
      setActiveInitiativeInput(false);
      store.changeCharInitiative(index, newInitiative);
    }
  };

  const handleNameKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const newName = event.currentTarget.value;
    if (event.key === "Enter") {
      setActiveNameInput(false);
      store.changeCharName(index, newName);
    }
  };

  useEffect(() => {
    setActiveInitiativeInput(false);
    setActiveNameInput(false);
  }, [store]);

  return (
    <div
      className={`${
        index === store.initiativePos ? "bg-[#FE5353] text-white" : ""
      } flex flex-row items-center py-4 px-10`}
    >
      <div className="flex-1 flex flex-col items-center h-32 w-0 justify-center">
        {index !== 0 &&
        index - 1 >= 0 &&
        store.getCharInitiative(index - 1) ===
          store.getCharInitiative(index) ? (
          <button>
            <AiOutlineArrowUp
              size={32}
              onClick={() => {
                store.changeArrayPosition(index, index - 1);
              }}
            />
          </button>
        ) : null}
        {index !== store.initiativeOrder.length - 1 &&
        index + 1 <= store.initiativeOrder.length - 1 &&
        store.getCharInitiative(index + 1) ===
          store.getCharInitiative(index) ? (
          <button>
            <AiOutlineArrowDown
              size={32}
              onClick={() => {
                store.changeArrayPosition(index, index + 1);
              }}
            />
          </button>
        ) : null}
      </div>
      <h3 className="hidden flex-1 text-center w-0 md:block">{index + 1}</h3> 
      {character.monsterData ? (
        <button
          className="flex-1 text-center w-0"
          onClick={() => setMonsterModalOpen(true)}
        >
          <h3>{character.name}</h3>
        </button>
      ) : (
        <button
          className="flex-1 text-center w-0"
          onClick={() => {
            if (!activeNameInput) {
              setCharName(store.getCharName(index));
              setActiveNameInput(true);
            }
          }}
        >
          {activeNameInput ? (
            <input
              maxLength={40}
              className={`mx-2 font-bold text-2xl text-center text-green-600 border-[0.3rem] border-black h-16 max-w-full dark:border-white dark:bg-black `}
              style={{
                width: `${charName.length + 2}rem`,
              }}
              type="text"
              name="characterName"
              onChange={(e) => {
                const newName = e.target.value;
                setCharName(newName);
              }}
              onKeyDown={handleNameKeyDown}
              value={charName}
            />
          ) : (
            <h3 className="max-w-full text-ellipsis overflow-hidden">
              {character.name}
            </h3>
          )}
        </button>
      )}
      {character.monsterData && (
        <MonsterModal
          monsterModalIsOpen={monsterModalIsOpen}
          setMonsterModalOpen={setMonsterModalOpen}
          monsterDetails={character.monsterData}
        />
      )}
      <button
        className="flex-1 w-0 text-center"
        onClick={() => {
          if (!activeInitiativeInput) {
            setCharInit(store.getCharInitiative(index));
            setActiveInitiativeInput(true);
          }
        }}
      >
        {activeInitiativeInput ? (
          <input
            className={`mx-2 font-bold text-2xl text-center text-green-600 border-[0.3rem] border-black h-16 max-w-full dark:border-white dark:bg-black`}
            style={{
              width: `${charInit.toString().length + 2}rem`,
            }}
            type="text"
            inputMode="numeric"
            maxLength={2}
            name="initiative"
            onChange={(e) => {
              const newInitiative = e.target.value;

              if (newInitiative.match("^[0-9]+$") || newInitiative === "") {
                setCharInit(newInitiative);
              }
            }}
            onKeyDown={handleInitiativeKeyDown}
            value={charInit}
          />
        ) : (
          <h3>{store.getCharInitiative(index)}</h3>
        )}
      </button>
      <div className="hidden flex-1 text-center w-0 md:block">
        <input
          className={`mx-2 font-bold text-2xl text-center text-green-600 border-[0.3rem] border-black h-16 dark:border-white dark:bg-black`}
          type="text"
          name="hitpoints"
          style={{
            width: `${store.getCharHp(index).toString().length + 2}rem`,
          }}
          onChange={(e) => {
            const newHitpoints = e.target.value;
            store.changeCharHp(index, newHitpoints);
          }}
          value={store.getCharHp(index)}
        />
      </div>
      <div className="flex justify-center flex-1 w-0">
        <button
          onClick={() => {
            setActiveInitiativeInput(false);
            setActiveNameInput(false);
            store.removeChar(index);
          }}
        >
          <AiOutlineCloseCircle size={32} />
        </button>
      </div>
    </div>
  );
};

export default InitiativeCharacter;
