import Layout from "../components/Layout";

const Error404Page = () => {
  return (
    <Layout>
      <h1>404: Page does not exist</h1>
      <p>{`Looks like this page does not exist... :(`}</p>
    </Layout>
  );
};

export default Error404Page;
